import { TabProps } from './utils'
import Tab from './tab'
import TabBody from './tab-body'
import { useState } from 'react'

interface PropsType {
    skills: Array<TabProps>
}

function Tabs({ skills }: PropsType) {
    const [activeTab, setActiveTab] = useState(skills[0].slug)

    return (
        <div className="mb-8">
            <ul className="flex border-b">
                {skills.map((skill: TabProps, index: number) => (
                    <Tab
                        key={index}
                        title={skill.title}
                        shortTitle={skill.shortTitle}
                        slug={skill.slug}
                        active={activeTab === skill.slug}
                        click={(slug) => {
                            setActiveTab(slug)
                        }}
                    ></Tab>
                ))}
            </ul>
            <ul className="border-b border-l border-r">
                {skills.map((skill: TabProps, index: number) => (
                    <TabBody
                        key={index}
                        slug={skill.slug}
                        text={skill.text}
                        active={activeTab === skill.slug}
                    ></TabBody>
                ))}
            </ul>
        </div>
    )
}

export default Tabs
