import { CompanyProps } from './utils'

function Company({
    name,
    title,
    calendar,
    info,
    logo,
    tech = [],
}: CompanyProps) {
    return (
        <div className="mb-8 px-2 sm:w-1/2 xs:inline-block text-base align-top">
            <h4 className="text-green-800 text-lg font-semibold">{title}</h4>
            <h5 className="text-green-600 text-sm">{name}</h5>
            <div className="text-slate-700 text-xs mb-4">{calendar}</div>
            <img
                className="w-full xs:w-auto max-h-40 w-auto mx-auto sm:max-h-32 md:max-h-48 mb-4"
                src={require(`./img/companies/${logo}`)}
                alt={name}
            ></img>
            <p className="mb-4">{info}</p>
            <div className="px-2 py-2 text-center border-t border-green-900 pt-4">
                {tech.map((techItem, index) => (
                    <div
                        className="inline-block mx-2 mb-2 last:mr-0"
                        key={index}
                    >
                        <img
                            className="w-auto h-6 "
                            src={require(`./img/tech/${techItem}.png`)}
                            alt={techItem}
                            title={techItem}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Company
