import { TabProps } from './utils'

function Tab({
    title,
    shortTitle,
    slug,
    click = (s: string) => {},
    active = false,
}: TabProps) {
    const liClasses =
        'mr-1 inline-block py-2 px-2 xs:px-4' +
        (active ? ' border-l border-t border-r rounded-t' : '')
    const aClasses =
        'bg-white font-semibold' +
        (active
            ? ' text-green-800'
            : ' text-green-600 hover:text-green-800 cursor-pointer')

    return (
        <li className={liClasses}>
            <span
                className={`inline-block sm:hidden ${aClasses}`}
                onClick={() => {
                    if (!active) {
                        click(slug)
                    }
                }}
            >
                {shortTitle}
            </span>
            <span
                className={`hidden sm:inline-block ${aClasses}`}
                onClick={() => {
                    click(slug)
                }}
            >
                {title}
            </span>
        </li>
    )
}

export default Tab
