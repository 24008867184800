import React from 'react'
import myPic from './img/me.jpg'
import MailIcon from './svg/mail'
import PageIcon from './svg/page'
import LinkedInIcon from './svg/linkedin'
import Tabs from './tabs'
import Company from './company'
import { Skills, Companies } from './texts'
import './App.css'

function App() {
    // jermaine.ns.cloudflare.com
    // jessica.ns.cloudflare.com
    const cvLink = `${process.env.PUBLIC_URL}/alex_sulga_cv_2024.pdf`

    return (
        <div className="min-w-80 max-w-5xl mx-auto">
            <header className="bg-[url(./img/header-bg.jpg)] h-96 md:h-80 md:flex md:flex-row-reverse pt-24 bg-cover bg-no-repeat bg-center opacity-95">
                <div className="md:basis-1/3">
                    <img
                        className="headshot h-36 w-36 mx-auto md:mx-4 rounded-full mb-2 border-2 border-green-950"
                        src={myPic}
                        alt="it's me"
                    />
                </div>
                <div className="md:basis-2/3 md:pt-8">
                    <h1 className="mb-4 text-3xl text-white text-center no-underline font-medium underline drop-shadow-md md:text-right">
                        Hi, I'm Alexei Sulga
                    </h1>
                    <div className="text-center mb-2 md:text-right">
                        <a
                            className="inline-block mr-2 transition-all hover:scale-125"
                            target="_blank"
                            rel="noreferrer"
                            href="https://formsubmit.co/el/kohiwo"
                        >
                            <MailIcon />
                        </a>
                        <a
                            className="inline-block mr-2 transition-all hover:scale-125"
                            target="_blank"
                            rel="noreferrer"
                            href={cvLink}
                        >
                            <PageIcon />
                        </a>
                        <a
                            className="inline-block transition-all hover:scale-125"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/sulga-alexei/"
                        >
                            <LinkedInIcon />
                        </a>
                    </div>
                </div>
            </header>
            <main>
                <article className="pt-4 px-4">
                    <h2 className="text-md text-black mb-8 text-center sm:text-left no-underline font-medium underline">
                        <b className="text-lg">Senior Front-end developer</b>{' '}
                        with experience of more than 15 years. Experience in
                        development and maintenance of e-commerce, blogs,
                        services, B2B, video streaming systems as back-end and
                        front-end. Development using vanilla JS, React.js,
                        Vue.js, Node.js, Typescript.
                    </h2>
                    <h3 className="text-3xl text-black mb-4 text-center no-underline font-medium underline">
                        My experience
                    </h3>
                    <Tabs skills={Skills}></Tabs>
                    <div></div>
                </article>
                <article className="pt-4 px-4">
                    <div className="text-left">
                        {Companies.map((c, index) => (
                            <Company
                                key={index}
                                name={c.name}
                                title={c.title}
                                calendar={c.calendar}
                                info={c.info}
                                logo={c.logo}
                                tech={c.tech}
                            ></Company>
                        ))}
                    </div>
                </article>
            </main>
        </div>
    )
}

export default App
