interface TabBodyPropTypes {
    text: string | undefined
    slug: string
    active: boolean
}

function TabBody({ text, slug, active = false }: TabBodyPropTypes) {
    const liClasses = 'mr-1 inline-block py-8 px-4' + (active ? '' : ' hidden ')

    return (
        <li className={liClasses} id={slug}>
            {text}
        </li>
    )
}

export default TabBody
